/* DOCUMENT READY */

$(function() {
    if ($("h1.cart_main_title.basket")) {
        var h1 = $("h1.cart_main_title.basket");
        
        h1.replaceWith('<span class="cart_main_title basket">' + h1.html() + '</span>');
    }

    // MODULES ADV
    if ($('.cms-page-module.four-images .swiper-container').length) {
        var cmsPageFourImages = new Swiper('.cms-page-module.four-images .swiper-container', {
            slidesPerView: 2.5,
            spaceBetween: 24,
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {  
                1020: {
                    slidesPerView: 1,
                    slidesPerGroup: 1, 
                },       
                1100: {
                    slidesPerView: 1.35,
                    slidesPerGroup: 1.35, 
                },                          
                1450: {
                    slidesPerView: 1.5,
                    slidesPerGroup: 1.5, 
                },  
                1700: {
                    slidesPerView: 2,
                    slidesPerGroup: 2, 
                }                             
            },
        });
    }

    // Header Burger Menu 
    $('.burger_menu_container').click(function() {
        $('.burger_menu').toggleClass('is_clicked');
        $('.bot_header_wrapper').toggleClass('is_open');
        $('#shad_menu').addClass('actif');
        $('#header_mention_id, .header').toggleClass('nav_burger_open');
        $('.burger_menu_close').addClass('visible');
        $('.ss_menu_categ_wrapper').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    });

    $('.burger_menu_close').click(function() {
        $('.burger_menu').toggleClass('is_clicked');
        $('.bot_header_wrapper').toggleClass('is_open');
        $('#shad_menu').removeClass('actif');
        $('#header_mention_id, .header').toggleClass('nav_burger_open');
        $(this).removeClass('visible');
    });

    // GIFTCARD PAGE
    if ($('.pageGiftCard').length && $('#breadcrumbs').length) {
        $("#breadcrumbs").detach().appendTo(".pageGiftCard__header .headerTitles");
    }

    /* BACK TO TOP FUNCTION */
    var stickyNavEl = $('#rayon_sticky_nav');
    var backToTopBtn = $('#back_to_top');

    if (stickyNavEl.length > 0) {
        var stickyNavTop = stickyNavEl.offset().top;
    }

    function stickyNav() {
        if (stickyNavEl.length > 0) {
            var scrollTop = $(window).scrollTop(); // our current vertical position from the top

            if (scrollTop > stickyNavTop) {
                stickyNavEl.addClass('sticky');
                $("#cart_top").appendTo(".filters_left");

            } else {
                stickyNavEl.removeClass('sticky');
                $("#cart_top").appendTo(".rightSide");
            }
        }
    };

    stickyNav();

    $(window).scroll(function () {
        stickyNav();

        if ($(window).scrollTop() > 300) {
            backToTopBtn.addClass('show');
        } else {
            backToTopBtn.removeClass('show');
        }
    });

    backToTopBtn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, 500, 'linear');
    });

    if($(".cabas .choices_list .wrapper_select").length){
        $(".cabas .choices_list .holder_scrollable").overlayScrollbars({});
    }

    
    //toggle filtre cms page journal
    $('.cms_menu_item.filter .bgSprite').click(function() {
        $('.cms_menu_item.filter').toggleClass("open");
    });

    // w-login-form insertAfter
    if ($(".w-newlogin-form").length && !$('body').hasClass('in_tunnel')) {
        $("p.legal_notices").insertAfter(".login_form_wrapper .title .subtitle.cache").show();
    }

    // Toggle the visibility of the 'back_to_top' element based on mouse events.
    $('.rolloverTxt').on('mouseenter', function () {
        if ($('#back_to_top').hasClass('show')) {
            setTimeout(function() {
                if ($('.header_rightSide').hasClass('hover')) {
                    $('#back_to_top').removeClass('show');
                }
            }, 100);
        }
    });

    $('#show_top_cart, .header_rightSide, .connexion_to').on('mouseleave', function () {
        setTimeout(function() {
            if (!$('#show_top_cart').hasClass('hover') && !$('.header_rightSide').hasClass('hover') && !$('.connexion_to').hasClass('hover')) {
                $('#back_to_top').addClass('show');
            }
        }, 100);
    });
    
    updateRolloverCartPosition();
    $(window).resize(updateRolloverCartPosition);
    $(window).scroll(updateRolloverCartPosition);
});

//Set rollover panier position when preheader & GBE banner present
function updateRolloverCartPosition() {
    const preheaderHeight = $('#header_mention_id').length && $('#header_mention_id').css('display') !== 'none'? $('#header_mention_id').outerHeight(): 0;
    const gbeBannerHeight = $('#FreeShippingBanner').length ? $('#FreeShippingBanner').outerHeight() : 0;
    // Get the height of #top_wrapper
    const topWrapperHeight = $('#top_wrapper').outerHeight();
    // Calculate the total height
    const totalHeight = preheaderHeight + topWrapperHeight + gbeBannerHeight;
    // Apply the total height as the "top" style for #show_top_cart
    $('#show_top_cart').css('--totalHeight', totalHeight + 'px');
    // Apply the total height as the "top" style for .wrap_search_field
    $('.wrap_search_field').css('top', totalHeight + 'px');
    // Apply the total height as the "top" style for #topLogin
    $('#topLogin').css('--totalHeight', totalHeight + 'px');
}

/* FUNCTIONS */
function processDisplayProductInList(obj, result) {
    var has_stock = false
    var i, k;
    var colors = Object.keys(result);

    // Parcours des infos de stock et MAJ dans la variable has_stock pour afficher ou non le bloc indisponible
    // Et grisement des tailles sans stock
    for (i = 0; i < colors.length -1; i++) {
        var color_id = colors[i];
        var sizes = Object.keys(result[color_id]);
        for (k = 0; k < sizes.length; k++) {
            var size_id = sizes[k];
            if (result[color_id][size_id]) {
                has_stock = true;
                obj.find('[name="itm_size"]').filter('[value="' + size_id + '"]').removeClass('disabled');
            } else {
                obj.find('[name="itm_size"]').filter('[value="' + size_id + '"]').addClass('disabled');
            }
        }
    }

    if (has_stock) {
        obj.removeClass('reminder_on');
    } else {
        obj.addClass('reminder_on');
    }
}


/* TRIGGER SHAD */
function triggerShad(shad, action) {
    shad = shad ? ('#shad_' + shad):'#shad' ;

    if (action && action == 'add') {
        $(shad).addClass('actif');
    } else if (action) {
        $(shad).removeClass('actif');
    } else {
        return;
    }
}

function initProductFieldset(id) {
    /*  Function to simulate the behavior of a select */
    var listColorSize = $("#" + id +".choices_list ");
    var shadSelect = $("#shad");

    listColorSize.addClass("opened");
    shadSelect.addClass("actif");
    
    shadSelect.on("click touch", function () {
        listColorSize.removeClass("opened");
        $(this).removeClass("actif");
    });
}

function exitProductFieldset(id) {
    var shadSelect = $(".shad.actif");
    var listColorSize;
    if (id !== undefined) {
        /*  Function to simulate the behavior of a select */
        listColorSize= $("#" + id +".choices_list");
    } else{
        listColorSize= $(".choices_list.opened");
    }
    listColorSize.removeClass("opened");
    shadSelect.removeClass("actif");
}